import ApiService from "@/core/services/ApiService";

export default function useApi() {

  const getDatas = async () => {
    return await ApiService.get("building_permit_wait");

  };

  const getData = async (id) => {
    return await ApiService.get("building_permit_wait/" + id);

  };

  return {
    getDatas,
    getData
  };
}
